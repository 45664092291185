<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <!-- <h3>新闻中心</h3>
              <p>NEWS</p> -->
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <!-- <div class="listSlidListInner on">
                <a href="/list">公司要闻</a>
                <span></span>
              </div>
              <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${urlName}about?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">关于我们</div>
              <div class="fr listHdGrop"><a :href="`/${urlName}`">首页</a> · <a :href="`${urlName}about`">关于我们</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer" v-html="pageCont">
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      hdTitle: '',
      hdName: '',
      pageCont: '公司名称：甘肃省公路建设管理集团有限公司',
      active: 0,
      slideHd: {
        title: '关于我们',
        sub: 'ABOUT US',
      },
      slideList:[
        {
          title: '关于我们',
          id: '1'
        }
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: []
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    
    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "151"});
      _this.slideList = data.data
      _this.hdTitle = _this.slideList[_this.active].name
      _this.hdName = _this.slideList[_this.active].name
      _this.listId = _this.slideList[_this.active].id
      if (_this.slideList[_this.active].have_list == 1 && _this.slideList[_this.active].id !== 439) {
        _this.pageType = 3
      } else if (_this.slideList[_this.active].have_list == 1 && _this.slideList[_this.active].id == 439) {
        _this.pageType = 4
      } else if (_this.slideList[_this.active].have_list == 0 && _this.slideList[_this.active].id == 440) {
        _this.pageType = 2
      } else {
        _this.pageType = 1
      }
      _this.getList(_this.slideList[_this.active].id)
    },
    // 查列表
    async getList(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      _this.loading = true
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.pageCont = data.data.content;
      _this.loading = false
    },
  }
}
</script>